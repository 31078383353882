<template>
  <div>
    <div class="mobileNav">
      <span class="text-dark">Plan</span> <span class="mx-2">></span>
      Other Plans
    </div>
    <b-container>
      <PlanSelector :isYourPlan="false" />
      <!-- plan type selector  -->

      <!-- advanced promotional plans - verison 3.0 -->
      <div v-show="promoCode !== ''">
        <div class="d-flex flex-row justify-content-center">
          <div
            class="d-flex flex-row justify-content-center align-items-center"
          >
            <p class="m-0 w-400 text-center" style="font-size: 18px; ">
              Plans
            </p>
            <el-switch
              class="mx-4"
              v-model="isSpeialPlanType"
              active-color="#36C2CF"
              inactive-color="#0597E7"
            >
            </el-switch>
            <p class="m-0 w-400 text-center" style="font-size: 18px;">
              Promo
            </p>
          </div>
        </div>
      </div>

      <!-- all plans   -->
      <b-row class="justify-content-center my-4">
        <b-col
          lg="4"
          md="6"
          sm="12"
          v-for="{
            pfId,
            pfAmt,
            dataCap,
            currencySymbol,
            calling,
            messages,
            dataOverRate,
            rogersplan,
            telusplan,
          } in postPlans"
          :key="pfAmt"
        >
          <div
            class="bg-main p-4 text-main m-3 text-light mycard"
            :class="
              carrier.charAt(0) !== getTheCarrier(rogersplan, telusplan)
                ? 'bg-block'
                : promoCode !== '' && isSpeialPlanType
                ? 'bg-green'
                : 'bg-blue'
            "
          >
            <div
              v-show="
                !(details.indexOf(pfAmt) > -1) &&
                  !(chooseError.indexOf(pfAmt) > -1)
              "
            >
              <!-- {{ pfId }} {{ currentPlanPfId }} -->
              <div
                class="d-flex flex-row justify-content-between align-items-center"
              >
                <div v-if="dataCap.split('.')[0] === 'Voice Only'">
                  <h2>Voice Only</h2>
                </div>
                <div v-else>
                  <h1>
                    {{ dataCap === "Unlimited" ? "Unlimited" : dataCap.split(".")[0] + " GB" }}
                  </h1>
                </div>

                <h3 class="font-weight-light" style="top: 30px; right: 30px">
                  {{ currencySymbol + " " + pfAmt }}
                </h3>
              </div>
              <p class="my-3">
                {{ calling }} & <br />
                {{ messages }}
              </p>
              <div
                class="d-flex flex-row justify-content-between align-items-center"
              >
                <button
                  class="my-3"
                  :class="
                    carrier.charAt(0) === getTheCarrier(rogersplan, telusplan)
                      ? 'mybtn'
                      : 'mybtn-block'
                  "
                  @click="
                    handleChoosePlan(
                      pfAmt,
                      dataCap,
                      pfId,
                      rogersplan,
                      telusplan
                    )
                  "
                >
                  choose
                </button>
                <div
                  class="d-flex flex-row justify-content-end align-items-center detailWrapper"
                  style="font-size: 16px; font-weight: 300"
                  @click="details.push(pfAmt)"
                >
                  Details
                  <i class="fas fa-chevron-circle-right ml-2 text-light"></i>
                </div>
              </div>
            </div>
            <div v-show="details.indexOf(pfAmt) > -1">
              <ul>
                <li v-if="dataCap !== 'Unlimited'">
                  <span v-if="dataCap === 'Voice Only'">
                    Voice Only <br />
                  </span>
                  <span v-else>
                    {{ dataCap }} High Speed 4G LTE/5G data <br />
                  </span>
                </li>
                <li>UNLIMITED Canada-wide calling</li>
                <li>UNLIMITED International text and picture messaging</li>
                <li>
                  Supported on the
                  {{ rogersplan ? "Rogers" : telusplan ? "Telus" : "Bell" }}
                  Network
                </li>
                <li>Call Display</li>
                <li>Voicemail - Upto 35 minutes of messages</li>
                <li>Data Overage Rate: {{ dataOverRate }}</li>
              </ul>
              <div
                class="d-flex flex-row justify-content-end align-items-center"
                style="font-size: 14px; font-weight: 300"
                @click="details.splice(details.indexOf(pfAmt), 1)"
              >
                <i class="fas fa-chevron-circle-up ml-2 text-light"></i>
              </div>
            </div>

            <div v-show="chooseError.indexOf(pfAmt) > -1">
              <div
                class="d-flex flex-column justify-content-between align-items-start"
              >
                <h1>Sorry !</h1>
                <p>
                  You are using {{ rogersplan ? "Telus" : telusplan ? "Rogers" : "Bell" }} Carrier. <br />
                  If you want to switch to {{ telusplan ? "Telus" : "Rogers" }}, please contact us at 1-855-886-0505. Thank you.
                </p>
                <button
                  class="mybtn-block"
                  @click="chooseError.splice(chooseError.indexOf(pfAmt), 1)"
                >
                  got it
                </button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <el-drawer :visible.sync="drawer" direction="btt">
      <div class="drawerWrapper text-center">
        <p style="margin-top: 2%">
          Plan Selected:
          <span class="w-500">{{ selectedPlan.dataCap }}</span> | Effective
          Date: <span class="w-500"> {{ effectiveDate }}</span>
        </p>
        <div
          class="selectedPlanWrapper d-flex flex-row justify-content-between align-items-center"
          @click="openTheConfirmationMsgBox"
        >
          <p class="m-0 w-500">Plan Fees</p>
          <p class="m-0">$ {{ selectedPlan.pfAmt + " / month" }}</p>
          <p class="m-0 w-500">Confirm</p>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import PlanSelector from "./PlanComponents/PlanSelector";
export default {
  name: "OtherPlans",
  components: {
    PlanSelector,
  },
  data() {
    return {
      drawer: false,
      // planType: "regularPlans",

      isSpeialPlanType: false,

      // client info
      balance: 0,
      carrier: "",
      currentPlanPfId: "",
      promoCode: "",
      // account status
      isPlanChangeRequested: "",
      isSuspensionRequested: "",
      isTerminationRequested: "",
      accountSuspended: false,
      suspensionType: "",
      // page info
      effectiveDate: "",
      postPlans: [],
      details: [],
      chooseError: [],
      // selected plan info
      selectedPlan: {
        pfAmt: "",
        dataCap: "",
        pfid: "",
        carrier: "",
      },
      tenYearAnniversaryPlanID: [955, 956, 957, 958, 959, 968],
    };
  },
  methods: {
    getTheCarrier(rogersplan, telusplan) {
      return rogersplan ? "R" : telusplan ? "T" : "B";
    },
    getAccountStatus() {
      this.$axios
        .get("Account/GetStatus")
        .then((response) => {
          let data = JSON.parse(response.data);
          if (data.PlanChangeStatus === "Requested") {
            this.isPlanChangeRequested = true;
          }
          if (data.SuspensionStatus === "Requested") {
            this.isSuspensionRequested = true;
          }
          if (data.TerminationStatus === "Requested") {
            this.isTerminationRequested = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProfile() {
      this.$axios
        .get("/Account/GetProfile")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.accountSuspended = data.Suspended;
          this.suspensionType = data.SuspensionReason;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReferralPromoCode() {
      this.$axios
        .get("/Account/ReferralPromoCode")
        .then((response) => {
          this.promoCode = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClientInfo() {
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.balance = data.CurrentBalance;
          this.carrier = data.Carrier;
          this.currentPlanPfId = data.CurrentPlan_pfId;
          // need to know client's carrier info first then call the api to process post plans eg. style bg-color and positions
          this.getPostPaidPlans("");
        })
        .catch((error) => {
          console.log(error);
        });
      this.getAccountStatus();
      this.getProfile();
      this.getReferralPromoCode();
    },
    // getPostPaidPlans -> 3 types of plans
    // type 1: no promocode + no anniversary
    // type 2: no promocode + anniversary
    // type 3: partner promocode
    getPostPaidPlans(value) {
      let urlQuery = "";
      if (value !== "") {
        urlQuery = "?promocode=" + value;
      }
      this.$axios
        .post("/Activation/PostpaidPlans" + urlQuery)
        .then((response) => {
          let postPlans = response.data;
          let availablePlans = [];
          let unavailablePlans = [];
          for (let item of postPlans) {
            let currentCarrier = this.getTheCarrier(
              item.rogersplan,
              item.telusplan
            );
            // remove current plan from plans
            if (item.pfId !== this.currentPlanPfId) {
              this.carrier.charAt(0) === currentCarrier
                ? availablePlans.push(item)
                : unavailablePlans.push(item);
            }
          }
          this.postPlans = availablePlans.concat(unavailablePlans);
        })
        .catch((error) => {
          console.log(error);
        });
      //Gary: 2021-04-15
      //Required by Chloe:
      //clients do not have thenYearAnniversaryPlan only display regular plans
      // if (this.tenYearAnniversaryPlanID.includes(this.currentPlanPfId)) {
      //   this.$axios
      //     .post("/Activation/PostpaidPlans")
      //     .then((response) => {
      //       let postPlans = response.data;
      //       let availablePlans = [];
      //       let unavailablePlans = [];
      //       for (let item of postPlans) {
      //         let currentCarrier = this.getTheCarrier(
      //           item.rogersplan,
      //           item.telusplan
      //         );
      //         // console.log(item);
      //         // if this plan is not the same plan as the one clinets currently use ->
      //         // push this plan into the postpaid plans array
      //         if (item.pfId !== this.currentPlanPfId) {
      //           this.carrier.charAt(0) === currentCarrier
      //             ? availablePlans.push(item)
      //             : unavailablePlans.push(item);
      //         }
      //       }
      //       this.postPlans = availablePlans.concat(unavailablePlans);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });

      // } else {
      //   let queryUrl = "?promocode=Regular%20plans";
      //   this.$axios
      //     .post("/Activation/PostpaidPlans" + queryUrl)
      //     .then((response) => {
      //       let postPlans = response.data;
      //       let availablePlans = [];
      //       let unavailablePlans = [];
      //       for (let item of postPlans) {
      //         let currentCarrier = this.getTheCarrier(
      //           item.rogersplan,
      //           item.telusplan
      //         );
      //         // console.log(item);
      //         // if this plan is not the same plan as the one clinets currently use ->
      //         // push this plan into the postpaid plans array
      //         if (item.pfId !== this.currentPlanPfId) {
      //           this.carrier.charAt(0) === currentCarrier
      //             ? availablePlans.push(item)
      //             : unavailablePlans.push(item);
      //         }
      //       }
      //       this.postPlans = availablePlans.concat(unavailablePlans);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // }
    },
    handleChoosePlan(pfAmt, dataCap, pfId, r) {
      this.selectedPlan.pfAmt = pfAmt;
      this.selectedPlan.dataCap = dataCap;
      this.selectedPlan.pfId = pfId;
      this.selectedPlan.carrier = r ? "R" : "T";
      if (this.carrier.charAt(0) === this.selectedPlan.carrier) {
        if (this.balance > 0) {
          this.$notify.error({
            title: "Error",
            message: "Please pay your outstanding balance first.",
            onClose: this.$router.push("/payment"),
          });
        } else {
          this.drawer = true;
          this.getTheEffectiveDate();
        }
      } else {
        this.chooseError.push(pfAmt);
        this.$notify.info({
          title: "Info",
          message: this.getInfoMessage(),
        });
      }
    },
    getDelayedMonth(now) {
      let month = now.getMonth() + 1;
      let date = now.getDate();
      let diff = 0;
      if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
        diff = 31 - date;
      } else {
        diff = 30 - date;
      }
      if (date >= 3 && diff >= 1) {
        return 1;
      } else {
        return 2;
      }
    },
    getTheEffectiveDate() {
      // Cut off Date: 2 DAY before the Last Date of the Month.
      // https://developer.gophonebox.com/APIReference/ClientAccountServices/ChangePlan.html#request-parameters
      // let now = new Date();
      // let date = null;
      // let delayedMonth = this.getDelayedMonth(now);
      // let day = now.getDate();
      // let nextMonth = now.getMonth() + delayedMonth;
      // let cutOffDay = 0;
      // if ([1, 3, 5, 7, 8, 10, 12].includes(nextMonth)) {
      //   cutOffDay = 31 - day;
      // } else {
      //   cutOffDay = 30 - day;
      // }
      // if (cutOffDay <= 1) {
      //   nextMonth++;
      // }
      // if (nextMonth - 11 >= 1) {
      //   date = new Date(now.getFullYear() + 1, nextMonth - 12, 2);
      // } else {
      //   date = new Date(now.getFullYear(), nextMonth, 2);
      // }
      // this.effectiveDate =
      //   date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

      let date = new Date();
      let firstDayNextMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        1
      );

      let cutOffDate = firstDayNextMonth.setDate(
        firstDayNextMonth.getDate() - 3
      );
      cutOffDate = new Date(cutOffDate);

      // if (date - cutOffDate > 0) {   // had issue of year-end from Nov
      //   date.setMonth(date.getMonth() + 3);
      //   this.effectiveDate =
      //     date.getFullYear() + "-" + date.getMonth() + "-" + 2;
      // } else {
      //   date.setMonth(date.getMonth() + 2);
      //   this.effectiveDate =
      //     date.getFullYear() + "-" + date.getMonth() + "-" + 2;
      // }

      // corrected above as following
      if (date - cutOffDate > 0) {
        date.setMonth(date.getMonth() + 2);
      } else {
        date.setMonth(date.getMonth() + 1);
      }
      this.effectiveDate =
        date.getFullYear() + "-" + (("0" + (date.getMonth() + 1)).slice(-2)) + "-02";
    },
    handleChangePlan() {
      if (this.isSuspensionRequested || this.isTerminationRequested) {
        this.$notify.error({
          title: "Error !",
          message:
            "Sorry, you cannot change your plan due to a pending suspension/termination request.",
        });
        this.drawer = false;
      } else if (this.accountSuspended && this.suspensionType === "VC") {
        this.$notify.error({
          title: "Error !",
          message:
            "Sorry, you cannot change your plan because you are on vacation plan now.",
        });
        this.drawer = false;
      } else if (this.accountSuspended && this.suspensionType === "LS") {
        this.$notify.error({
          title: "Error !",
          message:
            "Sorry, you cannot change your plan because your plan is suspended now due to reporte Lost/Stolen.",
        });
        this.drawer = false;
      } else {
        this.drawer = false;
        let url =
          "?pfid=" +
          this.selectedPlan.pfId +
          "&reason=" +
          "?" +
          "&effDate=" +
          this.effectiveDate;
        this.$axios
          .post("/Account/ChangePlan" + url)
          .then((response) => {
            // "Failed: Invalid date."
            if (response.data[9] === "I") {
              this.$notify.error({
                title: "Error !",
                message: "Sorry, You can not change the plan today.",
              });
            }
            // "Failed: Request duplicating."
            else if (response.data[9] === "R") {
              this.$notify.error({
                title: "Error !",
                message: "You have already requested to change your plan!",
              });
            } else {
              this.$notify({
                title: "Success",
                message:
                  "Plan Change Successfully! Your new plan will become active on the effective date!",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify.error({
              title: "Error !",
              message:
                "Fail to change your plan! Please contact our customer service for more details!",
            });
          });
      }
    },
    getInfoMessage() {
      let you;
      let us;
      if (this.carrier.charAt(0) === "B") {
        return `You are using Bell Carrier. If you want to switch to Telus or Rogers Network, please contact us at +1-855-886-0505. Thank you ! `;
      } else {
        if (this.carrier.charAt(0) === "T") {
          you = "Telus";
          us = "Rogers";
        } else {
          you = "Rogers";
          us = "Telus";
        }
        return `You are using ${you} Carrier. If you want to switch to ${us}, please contact us at +1-855-886-0505. Thank you ! `;
      }
    },
    openTheConfirmationMsgBox() {
      this.$confirm(
        `Do you wish to choose ${this.selectedPlan.dataCap} / $${this.selectedPlan.pfAmt} effective on ${this.effectiveDate}?`,
        "Confirmation",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(() => {
          this.handleChangePlan();
        })
        .catch(() => {
          this.drawer = false;
          this.$notify({
            title: "Success",
            message: "Your request has been cancelled.",
            type: "success",
          });
        });
    },
  },
  mounted() {
    this.$store.commit("setCurrentNav", "Plan");
    this.getClientInfo();
  },
  watch: {
    isSpeialPlanType(value) {
      value ? this.getPostPaidPlans(this.promoCode) : this.getPostPaidPlans("");
    },
  },
};
</script>

<style lang="scss" scoped>
.selectedPlanWrapper {
  background-image: linear-gradient(to right, #c43d29, #e83e25);
  color: #fff;
  border-radius: 10px;
  padding: 20px 30px;
  margin: 0 20px;
}

.selectedPlanWrapper:hover {
  cursor: pointer;
}

.drawerWrapper {
  width: 30%;
  margin: auto;
}

.detailWrapper:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .drawerWrapper {
    width: 100%;
    margin: auto;
  }
}
</style>
